import React from 'react';

import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Hyperlink, Image } from '@openedx/paragon';
import classNames from 'classnames';
import loginLogo from './login-logo.png';

import messages from './messages';

const MediumLayout = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      {/* Top red line */}
      <div className="red-line-top" />
      <div className="w-100 medium-screen-top-stripe" />
      <div className="w-100 p-0 mb-3 d-flex">
        <div className="col-md-10 bg-primary-400">
          <div className="d-flex align-items-center justify-content-center mb-4 ">
            <Image style={{ width: '450px', zIndex: 2, marginLeft: "0px" }} alt="Login Logo" src={loginLogo} />
          </div>
        </div>
        <div className="col-md-2 bg-white p-0">
          <svg className="w-100 h-100 medium-screen-svg-primary" preserveAspectRatio="xMaxYMin meet">
            <g transform="skewX(168)">
              <rect x="0" y="0" height="100%" width="100%" />
            </g>
          </svg>
        </div>
      </div>
      {/* Bottom red line */}
      <div className="red-line-bottom" />
    </>
  );
};

export default MediumLayout;
