import React from 'react';
import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Hyperlink, Image } from '@openedx/paragon';
import classNames from 'classnames';
import loginLogo from './login-logo.png';
import messages from './messages';

const LargeLayout = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      {/* Top red line */}
      <div className="red-line-top" />

      <div className="w-50 d-flex">
        <div className="col-md-9 bg-primary-400 position-relative">
          <div className="min-vh-100 d-flex justify-content-center align-items-center">
            <Image style={{ width: '450px', zIndex: 2, marginLeft: '-50px' }} alt="Login Logo" src={loginLogo} />
          </div>
        </div>
        <div className="col-md-3 bg-white p-0">
          <svg className="ml-n1 w-100 h-100 large-screen-svg-primary" preserveAspectRatio="xMaxYMin meet">
            <g transform="skewX(171.6)">
              <rect x="0" y="0" height="100%" width="100%" />
            </g>
          </svg>
        </div>
      </div>
      {/* Bottom red line */}
      <div className="red-line-bottom" />

    </>
  );
};

export default LargeLayout;
