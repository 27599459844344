import React, { useState, useEffect } from 'react';
import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Hyperlink, Image } from '@openedx/paragon';
import loginLogo from './login-logo.png';

const SmallLayout = () => {
  const { formatMessage } = useIntl();
  const [logoStyles, setLogoStyles] = useState({
    width: '450px', // Default width
    marginBottom: '50px', // Default bottom margin
  });

  useEffect(() => {
    const updateLogoStyles = () => {
      if (window.innerWidth <= 512) {
        setLogoStyles({
          width: '280px', // Smaller width for smaller viewports
          marginBottom: '30px', // Adjust bottom margin for smaller screens
        });
      } else {
        setLogoStyles({
          width: '450px', // Default width
          marginBottom: '50px', // Default bottom margin
        });
      }
    };

    // Set initial styles
    updateLogoStyles();

    // Add event listener to handle window resizing
    window.addEventListener('resize', updateLogoStyles);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', updateLogoStyles);
    };
  }, []);

  return (
    <>
      {/* Top red line */}
      <div className="red-line-top" />
      <span className="bg-primary-400 w-100">
        <div className="col-md-12 small-screen-top-stripe" />
        <div>
          <Hyperlink destination={getConfig().MARKETING_SITE_BASE_URL}>
            <Image
              className="logo-small"
              alt={getConfig().SITE_NAME}
              src={getConfig().LOGO_WHITE_URL}
              style={logoStyles}
            />
          </Hyperlink>
          <div className="d-flex justify-content-center" style={{ height: 'auto', paddingTop: '20px' }}>
            <Image
              alt="Login Logo"
              src={loginLogo}
              style={logoStyles}
            />
          </div>
        </div>
      </span>
      {/* Bottom red line */}
      <div className="red-line-bottom" />
    </>
  );
};

export default SmallLayout;
